<template>
    <div>
      <b-sidebar
        id="sidebar-task-handler"
        sidebar-class=""
        :visible="isTicketDescriptorActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        left
        width="30%"
        @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
        @hidden="clearForm"
      >
        <template #default="{ hide }">
                  <br/>
  
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center">
            <b-button
              v-if="taskLocal.id"
              size="sm"
              :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
              @click="taskLocal.isCompleted = !taskLocal.isCompleted"
            >
              {{ taskLocal.isCompleted ? 'Completed' : 'Mark Complete' }} 23
            </b-button>
            <h2
              v-else
              class="p-1"
            >
              Actualizar Ticket
            </h2>
            <div>
              <feather-icon
                v-show="taskLocal.id"
                icon="TrashIcon"
                class="cursor-pointer"
                @click="$emit('remove-task'); hide();"
              />
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="StarIcon"
                size="16"
                :class="{ 'text-warning': taskLocal.isImportant }"
                @click="taskLocal.isImportant = !taskLocal.isImportant"
              />
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </div>
          </div>
  
          <!-- Body -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
  
            <!-- Form -->
            <b-form
              class="p-1" style="font-size: 1.4em !important;"
              @submit.prevent="handleSubmit(updateTicket)"
              @reset.prevent="resetForm"
            >
  
  
                <b-form-group
                label="Remitente"              
              >
              <b-form-input readonly :value="task.sender_name" style="width: 100% !important;" />
              </b-form-group>
  
  
              <b-form-group
              label="Destinatario"              
                >
                <b-form-input readonly :value="task.resolver_name" style="width: 100% !important;" />
                <!-- <v-select :options="customersList" label="full_name" style="width: 100% !important;" @input="setResolver" /> -->
                </b-form-group>
  
                <b-form-group
              label="Tipo"              
                >
                <!-- <v-select :options="ticketsTypes" label="type" style="width: 100% !important;" @input="setTicketType" /> -->
                <b-form-input readonly :value="task.ticket_type.type" style="width: 100% !important;" v-if="typeof task.ticket_type === 'object'" />
                <!-- <v-select :options="customersList" label="full_name" style="width: 100% !important;" @input="setResolver" /> -->
                
                </b-form-group>
  
    
                
  
              <!-- Title -->
              <validation-provider
                #default="validationContext"
                rules="required"
              >
  
                <b-form-group
                label="Titulo"
              
                >
                <b-form-input readonly :value="task.title" style="width: 100% !important;" />
  
                  <b-form-invalid-feedback>
  
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
  
  
              <!-- Description -->
              <b-form-group
                label="Descripcion"
  
              >
                <!-- <quill-editor readonly
                  id="quil-content"
                  v-model="task.ticket"
                  :options="editorOption"
                  class="border-bottom-0"
                /> -->
                <div>
                  <!-- <iframe id="iframepdf" src="/test.pdf" style="width: 100%; height: 600px" /> -->
                  {{task.ticket}}
                </div>
              </b-form-group>

              <b-form-group
                label="Respuesta"
  
              >
                <quill-editor
                  id="quil-content"
                  v-model="task.response"
                  :options="editorOptionResponse"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar2"
                  class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
                <div>
                  <!-- <iframe id="iframepdf" src="/test.pdf" style="width: 100%; height: 600px" /> -->
                </div>
              </b-form-group>              
  
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  {{ taskLocal.id ? 'Update 2' : 'Actualizar Ticket' }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Cancelar
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive';
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email, url } from '@validations'
  import { avatarText } from '@core/utils/filter'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import { toRefs } from '@vue/composition-api'
  import { quillEditor } from 'vue-quill-editor'
  import useTaskHandler from './useTaskHandler'
  import store from '@/store';
  import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
  import Ticket from '@/lavarclick/ecommerce/Ticket.ts'
  
  export default {
    components: {
      // BSV
      BButton,
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BAvatar,
      BFormInvalidFeedback,
  
      // 3rd party packages
      vSelect,
      flatPickr,
      quillEditor,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
      prop: 'isTicketDescriptorActive',
      event: 'update:is-task-handler-sidebar-active',
    },
    props: {
      isTicketDescriptorActive: {
        type: Boolean,
        required: true,
      },
      task: {
        type: Object,
        required: true,
      },
      clearTaskData: {
        type: Function,
        required: true,
      },
      customersList: {
        type: Array,
        required: true
      }
      
    },
    data() {
      return {
        required,
        email,
        url,
      }
    },
    setup(props, { emit }) {
      const {
        taskLocal,
        resetTaskLocal,
  
        // UI
        assigneeOptions,
        onSubmit,
        tagOptions,
        resolveAvatarVariant,
      } = useTaskHandler(toRefs(props), emit)
  
      let newTicket = new Ticket();
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetTaskLocal, props.clearTaskData)
  
      const currentUserData = JSON.parse(localStorage.getItem('userData'));
  
      const editorOption = {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Este es mi mensaje ...',
      }

      const editorOptionResponse = {
        modules: {
          toolbar: '#quill-toolbar2',
        },
        placeholder: 'Este es la respuesta ...',
      }
  
      let ticketsTypes = [];
      const accountsManager = new AccountsManager();
  
      return {
  
        currentUserData,
        ticketsTypes,
        accountsManager,
        newTicket,
        // Add New
        taskLocal,
        onSubmit,
        assigneeOptions,
        tagOptions,
        editorOptionResponse,
        // Form Validation
        resetForm,
        clearForm,
        refFormObserver,
        getValidationState,
  
        // UI
        editorOption,
        resolveAvatarVariant,
  
        // Filter/Formatter
        avatarText,
        
      }
    },
    mounted() {
  
      this.accountsManager.getTicketTypes().then((ticketTypes) => {
            this.ticketsTypes = ticketTypes;
  
      });
  
    },
    methods: {
      updateTicket(eventx) {
        this.$emit('updateTicket', this.task);
      },
      setResolver(resolver) {
        this.newTicket.id_sender = this.currentUserData.id;
        this.newTicket.id_resolver = resolver.id_scanclick_users;
      },
      setTicketType(ticketType) {
        this.newTicket.ticket_type = ticketType;
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/quill.scss';
  </style>
  
  <style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include';
  
  .assignee-selector {
    ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
    }
  }
  
  #quil-content ::v-deep {
    > .ql-container {
      border-bottom: 0;
    }
  
    + #quill-toolbar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }

  </style>
  