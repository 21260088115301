<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class=""
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      width="30%"
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
                <br/>

        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center">
          <b-button
            v-if="taskLocal.id"
            size="sm"
            :variant="taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'"
            @click="taskLocal.isCompleted = !taskLocal.isCompleted"
          >
            {{ taskLocal.isCompleted ? 'Completed' : 'Mark Complete' }} 23
          </b-button>
          <h2
            v-else
            class="p-1"
          >
            Crear Ticket
          </h2>
          <div>
            <feather-icon
              v-show="taskLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-task'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="taskLocal.isImportant = !taskLocal.isImportant"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-1" style="font-size: 1.4em !important;"
            @submit.prevent="handleSubmit(createTicket)"
            @reset.prevent="resetForm"
          >


              <b-form-group
              label="Remitente"              
            >
            <b-form-input readonly :value="currentUserData.fullName+' '+'<'+currentUserData.username+'>'" style="width: 100% !important;" />
            </b-form-group>


            <b-form-group
            label="Destinatario"              
              >
              <v-select :options="customersList" label="full_name" style="width: 100% !important;" @input="setResolver" />
              </b-form-group>

              <b-form-group
            label="Tipo"              
              >
              <v-select :options="ticketsTypes" label="type" style="width: 100% !important;" @input="setTicketType" />
              </b-form-group>

  
              

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              rules="required"
            >

              <b-form-group
              label="Titulo"
            
              >
                <b-form-input
                  id="task-title"
                  v-model="newTicket.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  placeholder="Redacte Aqui el Ticket" textarea
                  style="width: 100% !important;"
                />

                <b-form-invalid-feedback>

                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <!-- Description -->
            <b-form-group
              label="Descripcion"

            >
              <quill-editor
                id="quil-content"
                v-model="newTicket.ticket"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
              <div>
                <!-- <iframe id="iframepdf" src="/test.pdf" style="width: 100%; height: 600px" /> -->
              </div>
            </b-form-group>

            <b-form-group
              label="Ordenes del Cliente"

            >
            <v-select :options="computedCustomerOrdersList" label="label" style="width: 100% !important;" @input="addOrderRelated" />
            </b-form-group>
            <b-form-group
              label="Ordenes Relacionadas"
            >

            <table class="ordersTableStyle">
              <tr>
                <th>
                  Order
                </th>
                <th>
                  Estado
                </th>
                <th>
                  Subtotal
                </th>
                <th>
                  Total
                </th>
              </tr>
              <tr v-for="(orderRelated, indexO) in computedRelatedOrdersList" v-bind:key="indexO">
                <td>
                  {{orderRelated.label}}
                </td>
                <td>
                  {{orderRelated.timeline.deadline_date === null ? 'Cotizacion' : 'Order'}}
                </td>
                <td>
                  {{orderRelated.total_price}}
                </td>
                <td>
                  {{orderRelated.total_price}}
                </td>
              </tr>
            </table>



            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskLocal.id ? 'Update 2' : 'Enviar Ticket' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, computed, ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import store from '@/store';
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import Ticket from '@/lavarclick/ecommerce/Ticket.ts'
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts'
import { resolve } from 'path'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    customersList: {
      type: Array,
      required: true
    }
    
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    let newTicket = new Ticket();
    const ecommerce = new ECommerce();
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const currentUserData = JSON.parse(localStorage.getItem('userData'));

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Este es mi mensaje...',
    }

    let ticketsTypes = [];

    const accountsManager = new AccountsManager();

    let customerOrdersList = ref([]);
    const computedCustomerOrdersList = computed({
      get: () => { return customerOrdersList.value; },
      set: (ordersList) => {
        customerOrdersList.value = ordersList;
      }
    })

    let relatedOrdersList = ref([]);
    const computedRelatedOrdersList = computed({
      get: () => { return relatedOrdersList.value; },
      set: (relatedOrdersList) => {
        relatedOrdersList.value = relatedOrdersList;
      }
    })    

    return {

      currentUserData,
      ticketsTypes,
      accountsManager,
      newTicket,
      ecommerce,
      computedCustomerOrdersList,
      computedRelatedOrdersList,
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
      
    }
  },
  mounted() {

    this.accountsManager.getTicketTypes().then((ticketTypes) => {
          this.ticketsTypes = ticketTypes;

    });

  },
  methods: {

    addOrderRelated(orderRelated) {

      this.computedRelatedOrdersList.push(orderRelated);

      console.log('defi ', this.computedRelatedOrdersList);

    },
    createTicket(eventx) {
      // this.newTicket.ticket.replace('<p>', '').replace('</p>', '');
      let bufferOrders = '';
      this.computedRelatedOrdersList.forEach((order, index) => {
        this.newTicket.related_orders += ''+order.id_order+',';

        if(index === (this.computedRelatedOrdersList.length-1)) {
          this.$emit('createTicket', this.newTicket);
        }
      });

    },
    async setResolver(resolver) {

      this.computedCustomerOrdersList = [];
      this.newTicket.id_sender = this.currentUserData.id;
      this.newTicket.sender_name = this.currentUserData.fullName;
      this.newTicket.id_resolver = resolver.id_scanclick_users;
      this.newTicket.resolver_name = resolver.names+' '+resolver.surnames;

      // console.log('millones', resolver); return true;

      await new Promise((resolve, reject) => {

        this.ecommerce.getECommerceOrders({ get_all: true,
          get_order_id: 0,
          get_quotes: false,
          get_customer_id: resolver.id_customer }).then((categoriesList) => {

            categoriesList.forEach((customerOrder) => {

              this.computedCustomerOrdersList.push({
                id_order: customerOrder.id_order,
                total_price: 0,
                total_services: customerOrder.order.logistic.collect.items.length,
                total_service_items: 0,
                total_discount: 0,
                total_tax: 0,
                total: 0, //(totalPrice.value-totalDiscount.value+totalTax.value)
                label: 'ORDER 0000'+customerOrder.id_order,
                timeline: customerOrder.timeline
              });
            });

            if(this.computedCustomerOrdersList.length === categoriesList.length) {
              console.log('termine');
              resolve(true);
            }
          });


      });

      console.log('hasta donde ', this.computedCustomerOrdersList);

    },
    setTicketType(ticketType) {
      this.newTicket.ticket_type = ticketType;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}


.ordersTableStyle {
    margin-left: 0px !important;
    border-collapse:collapse; 
    font-family: 'MavenPro' !important;
    border-radius: 10px !important; 
    width: 100% !important;
    margin-top: 0px !important;
    overflow-y: auto !important;
    height: 100% !important;
}


.ordersTableStyle th{ 
    border:#4e95f4 0px solid;
    font-size: 14px !important;
    color: #ffffff !important;
    background-color: #6d36ac !important;
    text-align: justify !important;
    font-family: 'MavenPro' !important;
    height: 20px !important;
    padding-left: 15px !important;
}

.ordersTableStyle td{ 
    border:#4e95f4 0px solid;
    text-align: justify !important;
    font-family: 'MavenPro' !important;
    padding-left: 15px !important;
    vertical-align: middle !important;
    

}
/* provide some minimal visual accomodation for IE8 and below */
.ordersTableStyle tr{
    background: #b8d1f3;
}
/*  Define the background color for all the ODD background rows  */
.ordersTableStyle tr:nth-child(odd){ 
    background: #f2edf7;
}
/*  Define the background color for all the EVEN background rows  */
.ordersTableStyle tr:nth-child(even){
    background: #f2faf0;
}

</style>
