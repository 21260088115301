<template>
  <div>
    <b-overlay
      :show="loading"
      rounded="sm" 
    >
    <div class="pricing-free-trial startTrainingHeaderStyle" >
      <b-row style="margin-top: -40px !important;"> 
        <b-col
          xl="12"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col  xl="12" cols="12">
                  <b-row>
                    <b-col xl="2" style="margin-top: 7px;">
                      <b-img
              fluid
              :src="require('@/assets/images/logo/logo2.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 120px; width: 120px"
            />
                    </b-col>
                    <b-col xl="7" class="mt-0">
                      <div class="text-primary" style="margin-top: 0px !important;">
                CONTROL DE SOPORTES <b>LAVARCLICK !</b> <span class="text-secondary">Administra Todos los Clientes, Aliados y Administradores de la Plataforma</span> 
                      </div>
              <!-- <b-form-group class="blog-search">

<b-input-group class="input-group-merge">
<b-form-input
id="search-input"
v-model="search_query"
placeholder="Buscar categoria o servicio ..."
/>
<b-input-group-append
class="cursor-pointer"
is-text
>
<feather-icon
icon="SearchIcon"
/>
</b-input-group-append>
</b-input-group>
</b-form-group> -->
                    </b-col>
                    <!-- <b-col xl="3" style="padding-top: 0px !important; font-size: 0.8em !important;">
                    ó Crea Una <b-img 
              fluid
              :src="require('@/assets/images/slider/addcat.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 60px; margin-top: 70px !important;"
              v-b-modal.modal-primary-form
            />
                    
                  </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
              
              
              <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
            </div>

            <!-- images -->
            <div style="height: 10% !important; width: 30% !important; margin-top: 30px !important; margin-right: -120px !important;">
              
            <b-img
              fluid
              :src="require('@/assets/images/illustration/team2.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 150px"
              v-b-modal.modal-primary
            />
            </div>
            
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    
    <b-row>
      <b-col xl="11">
        <div class="todo-app-list" style="width: 100% !important; margin-top: -100px !important;">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Buscar Ticket ..."
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <div class="dropdown">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />

            </template>
            <b-dropdown-item @click="resetSortAndNavigate">
              Reset Sort
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-asc' } }">
              Sort A-Z
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'title-desc' } }">
              Sort Z-A
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
              Sort Assignee
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
              Sort Due Date
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area" style="height: 600px !important; "
      >
        <draggable
        v-model="computedTicketsList"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >          
          <li
            v-for="task in computedTicketsList"
            :key="task.id_ticket"
            class="todo-item"
            :class="{ 'completed': task.resolved_at }"
            
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div>
                  <span @click="describeTicket(task)">TICKET # </span> <span style="font-size: 1.5em !important; background-color: #FFFFFF !important;" >{{ task.id_ticket }}</span>
                  <b-form-checkbox
                    :checked="task.resolved_at"
                    @click.native.stop
                    @change="updateTaskIsCompleted(task)"
                    style="margin-left: 30% !important;"
                  />
                </div>
              </div>
              <div class="todo-item-action">
                <table class="ticketTableStyle">
                  <tr>
                    <th colspan="3" style="background-color: #FFFFFF !important; color: blueviolet !important; border-style: solid !important; border-color: blueviolet !important; border-width: 0.1em !important;">
                      
                      <span class="todo-title">Categoria</span>{{ task.ticket_type.type }} <span class="todo-title">Titulo</span>{{ task.title }}
                      <span class="todo-title">Enviado</span>{{ task.sender_name }} <span class="todo-title">Responsable</span> {{ task.resolver_name }}
                  </th>
                  </tr>
                  <tr>
                      <th>
                        Fechas
                      </th>
                      <th>
                        Ticket
                      </th>
                      <th>
                        Seguimiento(s)
                      </th>
                  </tr>
                  <tr>
                    <td>
                      <div style="width: 200px !important;">
                      <span>Creado {{ dateFormat(task.created_at) }}</span><br/>
                      <span>Resuelto {{ task.resolved_at }}</span><br/>
                      <span>Cerrado {{ task.closed_at }}</span><br/>
                    </div>
                    </td>
                    <td>
                      <div style="width: 400px !important;">{{ task.ticket }}</div>
                    </td>
                    <td>
                      <div style="width: 300px !important;" v-for="(update, indexU) in task.response.split('$$$')" v-bind:key="indexU">
                        {{ update.split('##')[0] }}<br/>
                        {{ update.split('##')[1] }}
                      </div>
                    </td>
                  </tr>
                </table>
                  <!-- <b-badge
                    v-for="(orderID, indexOO) in task.related_orders.split(',')" v-bind:key="indexOO"
                    :key="orderID"
                    @click="describeOrder(orderID)"                    
                  >
                  <feather-icon
                    icon="UserIcon"
                    size="16"

                    v-b-modal.modal-primary-order
                  />

                  </b-badge>
                  <b-avatar
                  
                  size="32"
                  variant="light-secondary"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                </b-avatar> -->
                </div>
                <small class="text-nowrap text-muted mr-1">{{ formatDate(task.closed_at), { month: 'short', day: 'numeric'} }}</small>
                <!-- <b-avatar
                  v-if="task.created_at"
                  size="32"

                  :text="'hola'"
                /> -->
                
              </div>

          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !computedTicketsList.length}"
        >
          <h5>Aun No Hay Mensajes !</h5>
        </div>
      </vue-perfect-scrollbar>
      
    </div>
      </b-col>
      <b-col xl="1">
        <div style="">
      hola
    </div>

      </b-col>
    </b-row>    


    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      :customersList="computedCustomersList"
      @remove-task="removeTask"
      @update-task="updateTask"
      @createTicket="createTicket"
    />

    <TicketDescriptor
      v-model="isTicketDescriptorActive"
      :task="computedSelectedTicket"
      :clear-task-data="clearTaskData"
      :customersList="computedCustomersList"
      @remove-task="removeTask"
      @update-task="updateTask"
      @updateTicket="updateTicket"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      
      <todo-left-sidebar
        :task-tags="taskTags"
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
    
  </div>
  <div v-if="computedFlagDescribeOrder">
          
          <OrderDescriptor :order="computedOrderActive" @closeOrderDescriptor="closeOrderDescriptor"/>

    </div>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted, onMounted
} from '@vue/composition-api'
import {
  BFormInput, BImg, BCollapse, BRow, BCol, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BModal, BOverlay,
  BFormCheckbox, BBadge, BAvatar, VBModal
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import TicketDescriptor from './TicketDescriptor.vue';
import Ticket from '@/lavarclick/ecommerce/Ticket.ts'
import OrderDescriptor from '@/lavarclick/components/services/OrderDescriptor.vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BFormInput, BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    VBModal,
    draggable,
    VuePerfectScrollbar,
    TicketDescriptor,
    OrderDescriptor,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar, BCollapse, BRow, BImg, BCol
  },
  setup() {
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    let selectedTicket = ref({});
    let draa = ref();
    let loading = ref(true);
    const computedSelectedTicket = computed({
      get: () => { return selectedTicket.value },
      set: (ticket) => {
        selectedTicket.value = ticket
      }
    })

    // Register module
    if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
    })

    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankTask = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      store.dispatch('app-todo/addTask', val)
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }
    const removeTask = () => {
      store.dispatch('app-todo/removeTask', { id: task.value.id })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }
    const updateTask = taskData => {
      store.dispatch('app-todo/updateTask', { task: taskData })
        .then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isTaskHandlerSidebarActive = ref(false)
    const isTicketDescriptorActive = ref(false)

    const taskTags = [
      { title: 'Team', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'team' } } },
      { title: 'Low', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'low' } } },
      { title: 'Medium', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'medium' } } },
      { title: 'High', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'high' } } },
      { title: 'Update', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'update' } } },
    ]

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'high') return 'danger'
      if (tag === 'update') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchTasks = () => {
      store.dispatch('app-todo/fetchTasks', {
        q: searchQuery.value,
        filter: router.currentRoute.params.filter,
        tag: router.currentRoute.params.tag,
        sortBy: sortBy.value,
      })
        .then(response => {
          tasks.value = response.data
        })
    }

    fetchTasks()

    const handleTaskClick = taskData => {
      console.log('depo ', taskData)
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // const handleTicketDescriptorClick = taskData => {
    //   console.log('descriptor ', taskData)
    //   this.computedSelectedTicket = taskData;
    //   isTicketDescriptorActive.value = true
    // }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted
      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()


    const accountManager = new AccountsManager();
    const ecommerce = new ECommerce();

    let customersList = ref([]);
    let computedCustomersList = computed({
      get: () => { return customersList.value},
      set: (newList) => {
        customersList.value = newList;
      }
    });

    let ticketsList = ref([]);
    let computedTicketsList = computed({
      get: () => { return ticketsList.value},
      set: (newList) => {
        ticketsList.value = newList;
      }
    });

    let orderActive = ref();
    const computedOrderActive = computed({
        get: function() {
          return orderActive.value;
        },
        set: function(order) {
          orderActive.value = order;
        }

      });

    let flagDescribeOrder = ref(false);
    let computedFlagDescribeOrder = computed({
        get: () => {
          return flagDescribeOrder.value;
        },
        set: (flag) => {
          flagDescribeOrder.value = flag;
        }

      });
    let ticketsTypes = [];

    return {
      loading,
      accountManager,
      ticketsTypes,
      ecommerce,
      computedCustomersList,
      isTicketDescriptorActive,
      computedTicketsList,
      computedSelectedTicket,
      computedOrderActive,
      computedFlagDescribeOrder,
      draa,
      // handleTicketDescriptorClick,
      task,
      tasks,
      removeTask,
      addTask,
      updateTask,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,


      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,

    }
  },
  mounted() {

    this.ecommerce.getECommerceCustomer({
            get_all: true,
            get_active: true,
            get_customers_by_city: 0,
            id_employee: 0
      }).then((ecommerceList) => {

        this.computedCustomersList = ecommerceList;

    });

    this.accountManager.getTickets({
            get_all: true,
            get_my_send: 0,
            get_my_resolve: 0,
            get_my_send_open: 0,
            get_my_send_close: 0
      }).then((ecommerceList) => {

        console.log('timpo ', ecommerceList)
        this.computedTicketsList = ecommerceList;
        this.loading = false;

    });



  },
  directives: {
        'b-modal': VBModal,
        Ripple,
  },
  methods: {

    closeOrderDescriptor(eventx) {

      // this.computedFlagDescribeOrder = false;

    },

    createTicket(newTicket) {
      
      this.accountManager.createTicket(newTicket).then((ticketCreated) => {

        console.log('listo ', ticketCreated);

      })
    },
    describeTicket(ticket) {

      console.log('descriptor ', ticket)
      this.computedSelectedTicket = ticket;
      this.isTicketDescriptorActive = true
    
    },
    describeOrder(orderID) {
      this.$bvModal.show('modal-primary-order');
      this.ecommerce.getECommerceOrders({ get_all: false,
          get_order_id: orderID*1,
          get_quotes: false,
          get_customer_id: 0 }).then((categoriesList) => {

            let buf = categoriesList[0].order;
            buf.id_order = categoriesList[0].id_order;
            this.computedOrderActive = buf;
            this.computedFlagDescribeOrder = true;

            // console.log('ok', this.$bvModal['modal-primary-order']);
            console.log('order2 relaciona casa ', this.computedOrderActive);

          });

    },
    updateTicket(ticket) {

      this.accountManager.updateTicket(ticket).then((ticketCreated) => {

        console.log('updated ', ticketCreated);

      });

    },
    dateFormat(date) {
      return new Date(date*1).getFullYear()+'-'+(new Date(date*1).getMonth()+1)+'-'+new Date(date*1).getDate()
    }

  }
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-todo.scss";
.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 50px !important;
  width: 100% !important;
  font-family: 'MavenPro' !important;
}


.ticketTableStyle {
    margin-left: 0px !important;
    border-collapse:collapse; 
    font-family: 'MavenPro' !important;
    border-radius: 10px !important; 
    width: 900px !important;
    margin-top: 0px !important;
    overflow-y: auto !important;
    height: 100% !important;
}


.ticketTableStyle th{ 
    border:#4e95f4 0px solid;
    font-size: 14px !important;
    color: #ffffff !important;
    background-color: #6d36ac !important;
    text-align: justify !important;
    font-family: 'MavenPro' !important;
    height: 20px !important;
    padding-left: 15px !important;
}

.ticketTableStyle td{ 
    border:#4e95f4 0px solid;
    text-align: justify !important;
    font-family: 'MavenPro' !important;
    padding-left: 15px !important;
    vertical-align: middle !important;
    

}
/* provide some minimal visual accomodation for IE8 and below */
.ticketTableStyle tr{
    background: #b8d1f3;
}
/*  Define the background color for all the ODD background rows  */
.ticketTableStyle tr:nth-child(odd){ 
    background: #f2edf7;
}
/*  Define the background color for all the EVEN background rows  */
.ticketTableStyle tr:nth-child(even){
    background: #f2faf0;
}

</style>
